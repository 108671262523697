// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-series-jsx": () => import("/opt/build/repo/src/pages/about-the-series.jsx" /* webpackChunkName: "component---src-pages-about-the-series-jsx" */),
  "component---src-pages-archive-jsx": () => import("/opt/build/repo/src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-meet-the-executive-producer-jsx": () => import("/opt/build/repo/src/pages/meet-the-executive-producer.jsx" /* webpackChunkName: "component---src-pages-meet-the-executive-producer-jsx" */),
  "component---src-pages-meet-the-host-jsx": () => import("/opt/build/repo/src/pages/meet-the-host.jsx" /* webpackChunkName: "component---src-pages-meet-the-host-jsx" */),
  "component---src-pages-meet-the-senior-producer-jsx": () => import("/opt/build/repo/src/pages/meet-the-senior-producer.jsx" /* webpackChunkName: "component---src-pages-meet-the-senior-producer-jsx" */),
  "component---src-pages-message-received-jsx": () => import("/opt/build/repo/src/pages/message-received.jsx" /* webpackChunkName: "component---src-pages-message-received-jsx" */),
  "component---src-pages-see-you-soon-jsx": () => import("/opt/build/repo/src/pages/see-you-soon.jsx" /* webpackChunkName: "component---src-pages-see-you-soon-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

